
 


:root{
  --main-color:#585858;
  --border-color:#404040;
  --light-color:#7c3f69;
  --light-blue:#6A6D9E;
  --btn-color:#6750A4;
  --btn-text-title-color:#FAFAFC;
  --black-color: #27272A;
  --footer-color:#2C2C33;
  --slide-badge-color: #6750A433;
  --label-color:#414141;  
  --hover-color: #d5a5c5;
  --bar-scale-y: 0;
  --sparkle-color: rgb(253 244 215 / 40%);
}

*,
*::after,
*::before{
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}


body{
  background-color:var(--main-color);
  margin: 0;
  font-family: Calibri, Arial, sans-serif;
}

a{
  text-decoration: none;
}
a:hover{
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6{
  text-transform: uppercase;
}

.wrapper{
  width: 90%;
  max-width: 1920px;
  margin:0 auto;

}

.second-wrapper{
  width: 70%;
  max-width: 1920px;
  margin:0 auto;
  margin-top: 100px;
  margin-bottom: 150px;
}

.third-wrapper{
  width: 100%;
  max-width: 1920px;
  margin:0 auto;
}

/* header{

  width: 100%;
} */
nav{
  display: flex;
  justify-content: space-between;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  align-items: center;
  /* height: 126px; */
  height: 96px;
  width: 100%;
  transition: all 500ms ease;
  z-index: 100;
  left: 5%;
 
}
nav.scrolled{
  background: var(--main-color);
  box-shadow: 0 16px 14px -8px rgba(0, 0, 0, 0.3);
  top: 0;
  padding: 12px;
  width: 90%;
  border-radius: 0 0 10px 10px;
  position: fixed;
  z-index: 200;
  padding-right: 50px;
  padding-left: 50px;

}

nav .logo{
  width: 50%;
  /* height: 32.68px; */
}  
/* nav .logo img{
  margin-left: 80px;
}     */

nav.menu{
  width: 120px;
}

nav .menu > ul{
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 38px; */
  list-style: none;
}
nav .menu > ul > li{
  width: 110px;
  text-align: center;
}
nav .menu > ul > li > a{
  font-family: Calibri, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
  margin: 0;
  height: 96px;
  width: 110px;
}
nav .menu > ul > li > a:hover{
  background-color: #7c3f69;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  border-bottom: 1px solid #FFF;
}

nav .menu > ul > li > a.active{
  background-color: #7c3f69;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  border-bottom: 1px solid #FFF;
}

nav .login > ul{
  display: flex;
  align-items: center;
  list-style: none;
  gap: 5px;
}

nav .login > ul > li > a{
  font-family: Calibri, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
  margin: 0;
}

.desktop-menu-icon{
  cursor: pointer;
}
.desktop-menu-container{
  position: fixed;
  width: 100%;
  height:100vh;
  top: 0;
  left: 100%;
  background-color: #481238;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  transition: all 500ms ease;
  cursor: pointer;
}
.desktop-menu-container.active{
  left: 0;
}

.desktop-menu-container .desktop-menu-close{
  position: fixed;
  top: 47px;
  
  right: 30px;
  opacity: 0;
  pointer-events: none;
  transition: all 500ms ease;
}

.desktop-menu-close img{
  width: 37px;
}

.desktop-menu-container.active .desktop-menu-close{
  opacity: 1;
  pointer-events: auto;
}

.desktop-menu-container .logo{
  width: 100%;
  height: 32.68px;
}   

.desktop-menu-container .menu ul li{
  margin: 50px 10px;
 
}

.desktop-menu-container .menu > ul > li > a{
  font-family: 'Helvetica', 'Roboto Condensed', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
}

.desktop-menu-container .menu > ul > li > a:hover{
  color: var(--btn-color);
}

.desktop-menu-container .login{
  display: flex;
}
.desktop-menu-container .login > ul{
  
  align-items: center;
  justify-content: center ;
  list-style: none;
  gap: 5px;
}

.desktop-menu-container .slash::after{
  content: "/";
  color: #FFFFFF;
}

.desktop-menu-container .login > ul > li > a{
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
  
}

/* .slide-img{
  width: 100%;
  z-index: -1;
 margin-bottom: -200px;
} */
.slider{
  position: relative;
  }

.slider img{
  width: 100%;
  z-index: -1;
}
/* .slider .frame{
  width: 100%;
  height: 50%;
  background-color: #6750A4;
  z-index: 1;
} */

.slide-des{
  position: absolute;
  bottom: 10%;
  /* left: 10%; */
  /* transform: translate(-50%, -50%); */
  /* bottom: 16%;
  left: 16%; */

 
}
.slide-des h1{
  font-size:xxx-large;
  color: #FFF;
}



/* Project Slide */
.project-slider{
  position: relative;
   
}

.project-slider .frame{
  background-color: #53535300;
  max-width: 1920px;
  background-image: linear-gradient(180deg, rgba(88, 88, 88,0.2), rgba(88, 88, 88,1));
}

#projectSlideImg{
  width: 100%;
  height: 400px;
}

.project-slider img{
  width: 100%;
}

.project-slide-des{
  position: absolute;
  bottom: 20%;
}
.project-slide-des h1{
  font-size: 50px;
  color: #FFF;
  text-shadow: 2px 0 0 black;
}

.project-slide-des p{
  font-size:x-large;
  color: #FFF;
  margin: 0;
  text-shadow: 2px 0 0 black;
}


/* Blog Slide */
.blog-slider{
  position: relative;
}

.blog-slider .frame{
  background-color: #53535300;
  max-width: 1920px;
}

.blog-slider img{
  width: 100%;
}

.blog-slide-des{
  position: absolute;
  bottom: 20%;
}
.blog-slide-des h1{
  font-size: 50px;
  color: #FFF;
  text-shadow: 2px 0 0 black;
}

.blog-slide-des p{
  font-size:x-large;
  color: #FFF;
  margin: 0;
  text-shadow: 2px 0 0 black;
}


.img-fade{
  height: 400px;
   background-image: linear-gradient(180deg, rgba(88, 88, 88,0.01), rgba(88, 88, 88,1));
}

/* About Section */
.about-des{
  position: relative;
  background-color: var(--main-color);
  /* margin-top: 126px; */
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 50px;

}
.about-des h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
  text-align: center;
}
.about-des p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: justify;
}

/* Services */
.services{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20%;
  /* height: 250px; */
}
.service-items{  
  text-align: center;
  max-width: 250px;
  align-self: self-start;
  position: relative; 
  height: 270px;
}

#more {display: none;}
#more2 {display: none;}
#more3 {display: none;}
#more4 {display: none;}

.overlay {
  /* position: absolute;  */
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1; 
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  /* padding: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25%;
  padding-right: 25%;
  display: inline-block;
  text-align: center;
}

.service-items:hover .overlay {
  opacity: 1;
}

.service-items h3{
  color:#2c2c2c;
  margin-block-start: 10px;
}
.service-items img:focus{
  width: 100px;
  animation-name: icon-services;
  animation-duration: 2s;
}
.service-items img{
  width: 100px;
  animation-name: icon-services;
  animation-duration: 2s;

  /* animation: icon-services-rotate 1s linear infinite;
  /* animation-play-state: paused; *-/
  animation-delay: calc(var(--scroll) * -1s);

  animation-iteration-count: 2;
  animation-fill-mode:both; */
}

@keyframes icon-services {
  from {width: 50px;height: 50px;}
  to {width: 100px;height: 100px;}
}


.service-items p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: justify;
}

/* Leadership Section */
.leadership{
  display: flex;
  align-items: center;
  justify-content: left;
  /* margin-bottom: 50px; */

  width: 90%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.divider-leadership{
  display: flex;
  align-items: center;
  justify-content: space-between;
 
}
.divider-leadership .text-des{
  /* width: 50%; */
  /* margin-left: 5%; */
  margin-top: -15%;
  margin-left: 10%;
  margin-right: 10%;
}
.divider-leadership .text-des h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
  text-align: center;
}
.divider-leadership .text-des p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: center;
  /* width: 60%; */
}

.leadership-img{
  display: flex;
  align-items: end;
  justify-content: flex-end;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.leadership-img img{
 /* margin-right: 0; */
 margin-top: -300px;
 float: right;
}


/* Home Page Blog Section */
.blog{
  flex-direction: column;
  align-items: center;
  justify-content: censter;
  margin-bottom: 105px;
  text-align: left;
}
.blog h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
}
.blog-section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  /* gap: 65px; */
}
.blog-items{
  width: 336px;
  height: 207px;
  transform:0;
  transition: transform 1s;
  padding-bottom: 45px;
}
/* .blog-items:hover{
  transform: rotate(360deg);
} */
.blog-items img{
  border-radius: 10px;
  border: 1px solid #F7F7F7;
}
.blog-items p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: center;
  margin: 0;
}
.blog-date{
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-date img{
  width: 24px;
  border: none;
  border-radius: 0;
  margin-right: 10px;
  margin-top: 2px;
}
.blog-date span{
  padding: 0;
  margin-top: 3px;
  color: #F7F7F7;
}

/* Blog Detail */
.blog-detail{
  display: flex;
  align-items: center;
  justify-content: left;

  width: 90%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.divider-blog-detail{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divider-blog-detail .blog-detail-des{
  
  width: 70%;
  margin-top: 15%;
}
.divider-blog-detail .blog-detail-des h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
}
.divider-blog-detail .blog-detail-des p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 21px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: justify;
  width: 100%;
  line-height: 30px;
}

.blog-detail-leadership-img{
  display: flex;
  align-items: end;
  justify-content: flex-end;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.blog-detail-leadership-img img{
 /* margin-right: 0; */
 margin-top:  -100px;
 float: right;
}

.blog-detail-section{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-bottom: 20px;
}
.blog-detail-section h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
}

.blog-detail-section img{

  text-align: center;
  width: 100%;

}

/* Blogs Page */
.blogs{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  width: 90%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.divider-blogs{
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-top: 10%;
}
/* .divider-blogs hr{
  width: 70%;
  text-align: center;
} */
.blogs-items{
  display: flex;
  width: 90%;
  border-style: 1px solid #F7F7F7;
  margin-bottom: 30px;
  border-bottom: 1px solid #F7F7F7;
  /* box-shadow: 2px 4px 4px 2px rgba(128, 128, 128, 0.3); */
}
.blogs-date{
  display: flex;
 
}
.blogs-date span{
  padding: 0;
  margin-top: 3px;
  color: #F7F7F7;
}
.blogs-items img{
  /* border-radius: 10px;
  border: 2px solid #F7F7F7; */
  margin-right: 10px;
  height: 100%;
  border-radius: 2%;
} 
.blogs-items .blogs-des{
  padding: 10px;
}
.blogs-items .blogs-des h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #F7F7F7;
}
.blogs-items .blogs-des p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: justify;
  width: 100%;
  
}

.blogs-leadership-img{
  display: flex;
  align-items: end;
  justify-content: flex-end;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.blogs-leadership-img img{
 /* margin-right: 0; */
 margin-top:  -150%;
 float: right;
}

.blogs-section{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 20px;
}
.blogs-section h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
}

.blogs-section img{

  text-align: center;
  width: 100%;
  /* border-radius: 10px;
  border: 5px solid #F7F7F7; */
}


/*Project Section */
.project{
  display: flex;
  align-items: center;
  justify-content: left;
  /* margin-bottom: 50px; */

  width: 90%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.divider-project{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divider-project .project-des{
  
  /* margin-left: 5%; */
  margin-top: 25%;
}
.divider-project .project-des h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
}
.divider-project .project-des p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 21px;
  font-weight: 500;
  color: #f1f1f1;
  text-align: justify;
  width: 100%;
  line-height: 10px;
}

.divider-project .project-des strong{
  font-family: Calibri, Arial, sans-serif;
  font-size: 23px;
  font-weight: 900;
}

.project-leadership-img{
  display: flex;
  align-items: end;
  justify-content: flex-end;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.project-leadership-img img{
 /* margin-right: 0; */
 margin-top:  -200px;
 float: right;
}

.project-section{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  margin-bottom: 20px;
}
.project-section h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
}

.project-section img{

  text-align: center;
  width: 100%;
  /* border-radius: 10px;
  border: 5px solid #F7F7F7; */
}

/* Projects Page */
.projects{
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 50px;

  width: 90%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.divider-projects{
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-top: 10%;
}
/* .divider-blogs hr{
  width: 70%;
  text-align: center;
} */
.projects-items{
  display: flex;
  width: 100%;
  border-style: 1px solid #F7F7F7;
  margin-bottom: 30px;
  border-bottom: 1px solid #F7F7F7;
  /* box-shadow: 2px 4px 4px 2px rgba(128, 128, 128, 0.3); */
}
/* .projects-date{
  display: flex;
 
}
.projects-date span{
  padding: 0;
  margin-top: 3px;
  color: #F7F7F7;
} */
.projects-items img{
  /* border-radius: 10px;
  border: 2px solid #F7F7F7; */
  margin-right: 10px;
  border-radius: 2%;
} 
.projects-items .projects-des{
  padding:0 10px;
}
.projects-items .projects-des h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
  margin-top: 0;
}
.projects-items .projects-des p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: justify;
  width: 100%;
  
}

.projects-leadership-img{
  display: flex;
  align-items: end;
  justify-content: flex-end;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.projects-leadership-img img{
 /* margin-right: 0; */
 margin-top:  -150%;
 float: right;
}

.projects-section{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 20px;
}
.projects-section h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
}

.projects-section img{

  text-align: center;
  width: 100%;
  /* border-radius: 10px;
  border: 5px solid #F7F7F7; */
}
.img-construction{
  z-index: -1;
}

.project-des-section{
  width: 85%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;
}
.project-des-section h3{
  color: #F7F7F7;
}

.project-des-section p{
  color: #F7F7F7;
}

/* .projects{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  width: 90%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.divider-projects{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-top: 10%;
}
/* .divider-blogs hr{
  width: 70%;
  text-align: center;
} *s/
.projects-items{
  flex-direction: column;
  width: 70%;
  border-style: 1px solid #F7F7F7;
  margin-bottom: 10px;

}

.projects-items img{
  width: 100%;
} 
.projects-items .projects-des{
  padding: 10px;
}
.projects-items .projects-des h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
}
.projects-items .projects-des p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 21px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: justify;
  width: 100%;
  
}

.projects-leadership-img{
  display: flex;
  align-items: end;
  justify-content: flex-end;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.projects-leadership-img img{
 
 margin-top:  -100%;
 float: right;

}
.img-construction{
  z-index: -1;
}

.projects-section{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 20px;
}
.projects-section h2{
  font-family: Calibri, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #F7F7F7;
}

.projects-section img{

  text-align: center;
  width: 100%;

} */

/* Slide Show */

.slideshow-container {
  /* max-width: 800px; */
  position: relative;
  margin: auto;
}

.slides {
  display: none;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  margin-right: -45px;
  margin-left: -45px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 3px 0 0 3px;

}

.next {
  right: 0;
  border-radius: 0 3px 3px 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.navigator {
  text-align: center;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #481238;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #7c3f69;
}




/* Footer Section */
.footer{
  text-align: center;
  background-color: #585858;
  margin-top: 100px;
  padding-top: 47px;
  margin-bottom: -92px;
  display: flex;
  justify-content: space-around;
  width: 90%;
  max-width: 1920px;
  margin:0 auto;

}

.footer-logo{
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.vertical-divider-left{
  border: 2px solid #F7F7F7;
}



.contact-item{
  height: 100px;
}
.contact-item h4{
  font-family: Calibri, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #F7F7F7;
  line-height: 5px;
}
.contact-item p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #F7F7F7;
  line-height: 5px;
}

.customer{
  /* height: 60px; */
  padding-top: 1px;
}
.customer h4{
  font-family: Calibri, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #F7F7F7;
  line-height: 5px;
}
.customer p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #F7F7F7;
  line-height: 5px;
}

.vertical-divider-right{
  border: 2px solid #F7F7F7;
  margin-right: -45px;
}


.footer-social{
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 18px;
  z-index: 1;
}
.footer-social-logo{
  display: flex;
  align-items: end;
  justify-content: flex-end;
  margin-top: -260px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

#btn-to-top{
  float: right;
  margin-bottom: 30px;
  margin-right: 10px;
}

#btnTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  /* font-size: 18px; */
  border: none;
  outline: none;
  /* background-color: #481238; */
  color: white;
  cursor: pointer;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

#btnTop:hover {
  background-color: #481238;
}


.signature{
  text-align: center;
  font-size: xx-small;
  margin-top: -10px;
  color: #585858;
}
.signature a{
  color: #585858;
}
.signature a:hover{
  color: #585858;
}



nav.mobile-nav{
  display: none;
}

.mobile-menu-container{
  display: none;
}

.slider-mobile{
  display: none;
}









/* Mobile Version */

@media (max-width: 640px) {
  nav{
      display: none;
  }
  .desktop-menu-container{
      display: none;
  }
  .slider{
      display: none;
  }

  .wrapper{
      width: 90%;
     
  }
  .second-wrapper{
      width: 100%;
      max-width: 1920px;
      margin:0 auto;
      margin-top: 10px;
      margin-bottom: 15px;
  }
  .third-wrapper{
      width: 100%;
      max-width: 1920px;
      margin:0 auto;
  }
  /* nav.scrolled{
      position: fixed;
      padding: 12px;
      width: 50%;

  } */

  nav.mobile-nav{
      display: flex;
  }

  nav.mobile-nav .logo img{
      width: 100%;
  }

  nav.mobile-nav .menu-icon{
      width: 37px;
  }

  .menu-icon{
      cursor: pointer;
  }
  .mobile-menu-container{
      position: fixed;
      width: 100%;
      height:100vh;
      top: 0;
      left: 100%;
      background-color: #481238;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 200;
      transition: all 500ms ease;
      cursor: pointer;
  }
  .mobile-menu-container.active{
      left: 0;
  }

  .mobile-menu-container .menu-close{
      position: fixed;
      top: 47px;
      
      right: 30px;
      opacity: 0;
      pointer-events: none;
      transition: all 500ms ease;
  }

  .menu-close img{
      width: 37px;
  }

  .mobile-menu-container.active .menu-close{
      opacity: 1;
      pointer-events: auto;
  }
  
  .mobile-menu-container .logo{
      width: 100%;
      height: 32.68px;
  }   
  .mobile-menu-container .menu ul{
      padding: 0;
  }
  .mobile-menu-container .menu ul li{
      margin: 50px 10px;
      list-style: none;
      text-align: center;
  }

  .mobile-menu-container .menu > ul > li > a{
      font-family: 'Helvetica', 'Roboto Condensed', sans-serif;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
  }

  .mobile-menu-container .menu > ul > li > a:hover{
      color: var(--hover-color);
  }
  
  .mobile-menu-container .login{
      display: flex;
  }
  .mobile-menu-container .login > ul{
      
      align-items: center;
      justify-content: center ;
      list-style: none;
      gap: 5px;
  }
  
  .mobile-menu-container .slash::after{
      content: "/";
      color: #FFFFFF;
  }
  
  .mobile-menu-container .login > ul > li > a{
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
      
  }


  .slider-mobile{
      display: inline;
      position: relative;
  }
  

  .slider-mobile img{
      width: 100%;
      height: 100%;
      
  }
  .slide-mobile-des{
      position: absolute;
      bottom: 430%;
  }

  .slide-mobile-des h1{
      font-size: 100%;
      color: #FFF;
  }


  .project-slider{
      max-height: 30vh;
      
  }
  .project-slider img{
      width: 100%;

  }
   
  .project-slide-des{
      bottom: 10%;
  }
  .project-slide-des h1{
      font-size: 40px;
  }
  
  .project-slide-des p{
      font-size: 100%;
      color: #FFF;
      margin: 0;
  }
  
  /* Blog Slide */
.blog-slider{
  max-height: 30vh;
}

/* .blog-slider .frame{
  background-color: #53535300;
  height: 100vh;
} */

.blog-slider img{
  width: 100%;
}

.blog-slide-des{
  bottom: 10%;
}
.blog-slide-des h1{
  font-size: 40px;
}

.blog-slide-des p{
  font-size: 100%;
  color: #FFF;
  margin: 0;
}



  /* About Section */
  .about-des{
      margin-top: 0;
      width: 100%;
  }   
  .about-des h2{
      width: 100%;
  }
  
  /* Services */
  .services{
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 100px;
      height: 100%;
  }
  .service-items{  
      text-align: center;
      width: 100%;
      align-self: center;
      height: 170px;
  }
  .service-items p{  
      display: none;
  }
  /* .service-items img{
      width: 50%;
  } */

  
  /* Leadership Section */
  .leadership{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
  }
  .divider-leadership{
      flex-direction: column;
      align-items: center;
      justify-content: center;

  }
  .divider-leadership .text-des{
      width: 90%;
      z-index: 1;
  }
  .divider-leadership .text-des h2{
      text-align: center;
  }
 
  .divider-leadership .text-des p{
      text-align: justify;
      width: 100%;
  }
  
  
  /* Home Page Blog Section */
  .blog{
      flex-direction: column;
      text-align: center;
  }
  .blog h2{
      font-family: Calibri, Arial, sans-serif;
      font-size: 28px;
      font-weight: 700;
      color: #F7F7F7;
      text-align: center;
  }
  .blog-section{
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
  }
  .blog-items{
      width: 90%;
      height: 100%;
  }
  .blog-items img{
      width: 100%;
  }
  .blog-items .blog-date img{
      width: 24px;
  }
  .blog-items p{
      font-size: 18px;
  }

  /* Blog Detail */
.divider-blog-detail .blog-detail-des{
  width: 100%;
  margin-top: 35%;
}
.divider-blog-detail .blog-detail-des p{

  line-height: 30px;
}

/* .blog-detail-section{

  width: 100%; 
} */




  /* Blogs Page */
.blogs{
  flex-direction: column;
  align-items: center;
  width: 90%; 
  margin-top: 100px;
}
.divider-blogs{
  flex-direction: column;
  margin-top: 10%;
}

.blogs-items{
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
}
.blogs-date{
  display: flex; 
}
.blogs-items .blogs-date img{
  width: 24px;
}

.blogs-items img{
  width: 100%;
} 
 


  /*Home Page Project Section */

.divider-project{
 flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.divider-project .project-des{
  z-index: 1;
  margin-top:  50%;
}

.divider-project .project-des p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 21px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: left;
  width: 100%;
  line-height: normal;
}


.project-leadership-img img{
 /* margin-right: 0; */
 margin-top:  -200px;
 float: right;
}

.project-section{
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
   
}


/* Projects Page */
.projects{
  flex-direction: column;
  align-items: center;
  width: 90%; 
  margin-top: 100px;
}
.divider-projects{
  flex-direction: column;
  margin-top: 10%;
}

.projects-items{
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
}
.projects-date{
  display: flex; 
}
.projects-items .projects-date img{
  width: 24px;
}

.projects-items img{
  width: 100%;
} 
/* .projects{
  flex-direction: column;
  width: 100%;
}
.divider-projects{
  flex-direction: column;
  margin-top: 30%;
}

.projects-items{
  flex-direction: column;
  width: 90%;
}

.img-construction{
  z-index: -1;
} */


  
  /* Footer Section */
  .footer{
      padding-top: 37px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

  }
  
 
   
  .vertical-divider-left{
      width: 90%;
      background-color:#F7F7F7;
  }
  
   

  .contact-item h4{

      line-height: 15px;
  }
  .contact-item p{

      line-height: 0;
  }
  
  .customer{  
      padding-top: 1px;
      /* height: 60px; */
  }
  .customer h4{
      line-height: 5px;
  }
  .customer p{
      line-height: 0;
  }
  
  .vertical-divider-right{
      margin-top: 0;
      width: 90%;
      margin-left: 10%;
      margin-right: 10%;
  }

  .footer-social{
      display: flex;
      align-items: end;
      justify-content: space-between;
      gap: 18px;
      z-index: 1;
      width: 50%;
      margin-top: 50px;
  }
  .footer-social-logo{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
  }
  




}

@media screen and (min-width: 641px) and (max-width: 810px) {
  nav{
      display: none;
  }
  .desktop-menu-container{
      display: none;
  }

  .wrapper{
      width: 90%;
     
  }
  .second-wrapper{
      width: 80%;
      max-width: 1920px;
      margin:0 auto;
      margin-top: 10px;
      margin-bottom: 15px;
  }
  .third-wrapper{
      width: 100%;
      max-width: 1920px;
      margin:0 auto;
  }
  /* nav.scrolled{
      position: fixed;
      padding: 12px;
      width: 50%;

  } */

  nav.mobile-nav{
      display: flex;
  }


  nav.mobile-nav .menu-icon{
      width: 37px;
      cursor: pointer;
  }

  .menu-icon{
      cursor: pointer;
  }
  .mobile-menu-container{
      position: fixed;
      width: 100%;
      height:100vh;
      top: 0;
      left: 100%;
      background-color: #481238;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 200;
      transition: all 500ms ease;
      cursor: pointer;
  }
  .mobile-menu-container.active{
      left: 0;
  }

  .mobile-menu-container .menu-close{
      position: fixed;
      top: 47px;
      cursor: pointer;
      right: 30px;
      opacity: 0;
      pointer-events: none;
      transition: all 500ms ease;
  }

  .menu-close img{
      width: 37px;
  }

  .mobile-menu-container.active .menu-close{
      opacity: 1;
      pointer-events: auto;
  }
  
  .mobile-menu-container .logo{
      width: 100%;
      height: 32.68px;
  }   
  .mobile-menu-container .menu ul{
      padding: 0;
  }
  .mobile-menu-container .menu ul li{
      margin: 50px 10px;
      list-style: none;
  }

  .mobile-menu-container .menu > ul > li > a{
      font-family: 'Helvetica', 'Roboto Condensed', sans-serif;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
  }

  .mobile-menu-container .menu > ul > li > a:hover{
      color: var(--hover-color);
  }
  
  .mobile-menu-container .login{
      display: flex;
  }
  .mobile-menu-container .login > ul{
      
      align-items: center;
      justify-content: center ;
      list-style: none;
      gap: 5px;
  }
  
  .mobile-menu-container .slash::after{
      content: "/";
      color: #FFFFFF;
  }
  
  .mobile-menu-container .login > ul > li > a{
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
      
  }


  .slider img{
      width: 100%;
      height: 100%;
  }


  .slide-des h1{
      font-size: 100%;
  }


  .project-slider{
      max-height: 50vh;
      
  }
  .project-slider img{
      width: 100%;
  }
   
  .project-slide-des{
      bottom: 10%;
  }
  .project-slide-des h1{
    font-size: 46px;
  }
  
  .project-slide-des p{
      font-size: 100%;
      color: #FFF;
      margin: 0;
  }

      /* Blog Slide */
.blog-slider{
  max-height: 50vh;
}

/* .blog-slider .frame{
  background-color: #53535300;
  height: 100vh;
} */

.blog-slider img{
  width: 100%;
}

.blog-slide-des{
  bottom: 10%;
}
.blog-slide-des h1{
  font-size: 46px;
}

.blog-slide-des p{
  font-size: 100%;
  color: #FFF;
  margin: 0;
}

  /* About Section */
  .about-des{
      margin-top: 0;
  }   
  .about-des h2{
      width: 100%;
  }
  
  /* Services */
  .services{
      height: 100%;
      display: grid;
      gap: 1% 1%;
      grid-template-columns: auto auto;
  }
  .service-items{  
      text-align: center;
      width: 100%;
      align-self: self-start;
  }
  /* .service-items img{
      width: 50%;
  } */

  
  /* Leadership Section */
  .leadership{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
  }
  .divider-leadership{
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .divider-leadership .text-des{
      width: 90%;
      z-index: 1;
  }
  .divider-leadership .text-des h2{
      text-align: center;
  }
 
  .divider-leadership .text-des p{

      width: 100%;
  }


  
  /*Home Page Blog Section */
  .blog{
      flex-direction: column;
      
      text-align: center;
  }

  .blog-section{
      flex-direction: column;

  }
  .blog-items{
      width: 100%;
      height: 100%;
      text-align: center;
  }
  /* .blog-items img{
      border-radius: 10px;
      border: 2px solid #F7F7F7;
      width: 100%;
  } */
  .blog-items img{
      width: 80%;
  }
  .blog-items .blog-date img{
      width: 24px;
  }
  .blog-items p{
      font-size: 18px;
  }

  .blog-date{
      align-items: center;
      justify-content: center;
  }


      /* Blog Detail */
.divider-blog-detail .blog-detail-des{
  width: 100%;
  margin-top: 35%;
}
.divider-blog-detail .blog-detail-des p{

  line-height: 30px;
}

/* .blog-detail-section{

  width: 100%; 
} */

      /* Blogs Page */
.blogs{
  flex-direction: column;
  align-items: center;
  width: 90%; 
  margin-top: 100px;
}
.divider-blogs{
  flex-direction: column;
  margin-top: 10%;
}

.blogs-items{
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
}
.blogs-date{
  display: flex; 
}
.blogs-items .blogs-date img{
  width: 24px;
}

.blogs-items img{
  width: 100%;
} 
  
  
  /* Project Section */

  .divider-project{
      flex-direction: column;
       align-items: center;
       justify-content: space-between;
   }
   .divider-project .project-des{
       z-index: 1;
       
   }
    
   .divider-project .project-des p{
       font-family: Calibri, Arial, sans-serif;
       font-size: 21px;
       font-weight: 500;
       color: #F7F7F7;
       text-align: left;
       width: 100%;
       line-height: normal;
   }
   
    
   .project-leadership-img img{
      /* margin-right: 0; */
      margin-top:  -200px;
      float: right;
   }
   
   .project-section{
       display: block;
       margin-left: auto;
       margin-right: auto;
       /* width: 100%;        */
   }
/* Projects Page */
.projects{
  flex-direction: column;
  align-items: center;
  width: 90%; 
  margin-top: 100px;
}
.divider-projects{
  flex-direction: column;
  margin-top: 10%;
}

.projects-items{
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
}

.projects-items img{
  width: 100%;
} 
/* .projects{
  flex-direction: column;
  width: 100%;
}
.divider-projects{
  flex-direction: column;
  margin-top: 30%;
}

.projects-items{
  flex-direction: column;
  width: 90%;
}

.img-construction{
  z-index: -1;
} */

  
  
  /* Footer Section */
  .footer{
      padding-top: 37px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

  }
  
 
   
  .vertical-divider-left{
      width: 90%;
      background-color:#F7F7F7;
  }
  
   

  .contact-item h4{
      line-height: 15px;
  }
  .contact-item p{
      line-height: 0;
  }
  
  .customer{  
      padding-top: 1px;
      /* height: 60px; */
  }
  .customer h4{
      line-height: 5px;
  }
  .customer p{
      line-height: 0;
  }
  
  .vertical-divider-right{
      margin-top: 0;
      width: 90%;
      margin-left: 10%;
      margin-right: 10%;
  }

  .footer-social{
      display: flex;
      align-items: end;
      justify-content: space-between;
      gap: 18px;
      z-index: 1;
      width: 50%;
      margin-top: 50px;
  }
  .footer-social-logo{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
  }

}
@media screen and (min-width: 811px) and (max-width: 989px) {

  nav{
      display: none;
  }
  

  .wrapper{
      width: 90%;
     
  }
  .second-wrapper{
      width: 70%;
      max-width: 1920px;
      margin:0 auto;
      margin-top: 10px;
      margin-bottom: 15px;
  }
  .third-wrapper{
      width: 100%;
      max-width: 1920px;
      margin:0 auto;
  }
  /* nav.scrolled{
      position: fixed;
      padding: 12px;
      width: 50%;

  } */

  nav.mobile-nav{
      display: flex;
  }

  nav.mobile-nav .menu-icon{
      width: 37px;
      cursor: pointer;
  }

  .menu-icon{
      cursor: pointer;
  }
  .mobile-menu-container{
      position: fixed;
      width: 100%;
      height:100vh;
      top: 0;
      left: 100%;
      background-color: #481238;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 200;
      transition: all 500ms ease;
      cursor: pointer;
  }
  .mobile-menu-container.active{
      left: 0;
  }

  .mobile-menu-container .menu-close{
      position: fixed;
      top: 47px;
      cursor: pointer;
      right: 30px;
      opacity: 0;
      pointer-events: none;
      transition: all 500ms ease;
  }

  .menu-close img{
      width: 37px;
  }

  .mobile-menu-container.active .menu-close{
      opacity: 1;
      pointer-events: auto;
  }
  
  .mobile-menu-container .logo{
      width: 100%;
      height: 32.68px;
  }   
  .mobile-menu-container .menu ul{
      padding: 0;
  }
     
  .mobile-menu-container .menu ul li{
      margin: 50px 10px;
      list-style: none;
  }

  .mobile-menu-container .menu > ul > li > a{
      font-family: 'Helvetica', 'Roboto Condensed', sans-serif;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
  }

  .mobile-menu-container .menu > ul > li > a:hover{
      color: var(--hover-color);
  }
  
  .mobile-menu-container .login{
      display: flex;
  }
  .mobile-menu-container .login > ul{
      
      align-items: center;
      justify-content: center ;
      list-style: none;
      gap: 5px;
  }
  
  .mobile-menu-container .slash::after{
      content: "/";
      color: #FFFFFF;
  }
  
  .mobile-menu-container .login > ul > li > a{
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
      
  }


  .slider img{
      width: 100%;
      height: 100%;
      
  }

  /* .slider {
  background-image: url(../../assets/images/slide-2.jpg);
  background-size: cover;
  background-attachment:scroll;
  background-position-y: top; 
  
  } */
  /* .slide-des{
      top:25%;
      left:25%;
  } */
  .slide-des h1{
      font-size: 100%;
  }

  /* About Section */
  /* .about-des{
      margin-top: -100px;
  }   
  .about-des h2{
      width: 100%;
  } */
  
  /* Services */
  .services{
      height: 100%;
      display: grid;
      gap: 1% 1%;
      grid-template-columns: auto auto;
  }
  .service-items{  
      text-align: center;
      width: 100%;
      align-self: self-start;
  }
  /* .service-items img{
      width: 50%;
  } */

  
  /* Leadership Section */
  .leadership{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
  }
  .divider-leadership{
      flex-direction: column;
      align-items: center;
      justify-content: center;

  }
  .divider-leadership .text-des{
      width: 90%;
      z-index: 1;
  }
  .divider-leadership .text-des h2{
      text-align: center;
  }
 
  .divider-leadership .text-des p{
      text-align: justify;
      width: 100%;
  }
  
  
  /* Blog Section */
  .blog{
      text-align: left;

  }

  .blog-section{
      gap: 2%;
      width: 100%;
  }
  .blog-items{
      width: 50%;
      
  }
  .blog-items img{
      width: 100%;
  }
  .blog-items .blog-date img{
      width: 24px;
  }
  .blog-items p{
      font-size: 18px;
  }
  .blog-date{
      align-items: center;
      justify-content: center;
  }
  .blog-date img{
      width: 24px;
  }
  
          /* Blog Page */
.blogs{
  display: flex;
  align-items: center;
  width: 90%; 
  margin-top: -30%;
}
.divider-blogs{
  flex-direction: column;
  margin-top: 40%;
}

.blogs-items{
  display: flex;
  width: 100%;
}
.blogs-date{
  display: flex; 
}
.blogs-items .blogs-date img{
  width: 24px;
}

.blogs-items img{
  width: 100%;
} 

.project-section img{
  width: 100%;
}

/* Projects Page */
.projects{
  display: flex;
  align-items: center;
  width: 90%; 
  margin-top: -30%;
}
.divider-projects{
  flex-direction: column;
  margin-top: 40%;
}
  
.projects-items{
  display: flex;
  width: 100%;
}
.projects-date{
  display: flex; 
}
.projects-items .projects-date img{
  width: 24px;
}
.img-construction{
  z-index: -1;
}
  
.projects-items img{
  width: 100%;
} 

  /* Footer Section */
  .footer{
      padding-top: 37px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

  }
  
 
   
  .vertical-divider-left{
      width: 90%;
      background-color:#F7F7F7;
  }
  
   

  .contact-item h4{

      line-height: 15px;
  }
  .contact-item p{
      line-height: 0;
  }
  
  .customer{  
      padding-top: 1px;
      /* height: 100px; */
  }
  .customer h4{
      line-height: 5px;
  }
  .customer p{
      line-height: 0;
  }
  
  .vertical-divider-right{
      margin-top: 10;
      width: 90%;
      margin-left: 10%;
      margin-right: 10%;
  }

  .footer-social{
      display: flex;
      align-items: end;
      justify-content: space-between;
      gap: 18px;
      z-index: 1;
      width: 50%;
      margin-top: 50px;
  }
  .footer-social-logo{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
  }


}



@media screen and (min-width: 990px) and (max-width: 1170px) {
  .wrapper{
      width: 90%;
     
  }
  .second-wrapper{
      width: 70%;
      max-width: 1920px;
      margin:0 auto;
      margin-top: 10px;
      margin-bottom: 15px;
  }
  .third-wrapper{
      width: 100%;
      max-width: 1920px;
      margin:0 auto;
  }
  .slider img{
      width: 100%;
      height: 100%;
      
  }
/* 
  .slide-des{
      top:25%;
      left:25%;
  } */

  /* Services */
  .services{
      height: 100%;
      display: grid;
      gap: 1% 1%;
      grid-template-columns: auto auto;
  }
  .service-items{  
      text-align: center;
      width: 100%;
      align-self: self-start;
  }
  /* .service-items img{
      width: 50%;
  } */


  .service-items p{
  font-family: Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #F7F7F7;
  text-align: justify;
  }

  /* Leadership Section */
  .leadership{
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
  }
  .divider-leadership{
      flex-direction: column;
      align-items: center;
      justify-content: center;

  }
  .divider-leadership .text-des{
      width: 90%;
      z-index: 1;
  }
  .divider-leadership .text-des h2{
      text-align: center;
  }
 
  .divider-leadership .text-des p{
      text-align: justify;
      width: 100%;
  }



  /* Blog Section */
  .blog{
      text-align: left;

  }

  .blog-section{
      gap: 2%;
  width: 100%;
  }
  .blog-items{
      width: 50%;
      
  }
  .blog-items img{
      width: 100%;
  }
  .blog-items p{
      font-size: 18px;
  }
  .blog-date{
      align-items: center;
      justify-content: center;
  }
  .blog-date img{
      width: 24px;
  }
}  


/* @media screen and (min-width: 1171px) {
  .slider img{
      width: 100%;
      height: 100%;
      
  }

  .slide-des{
      top: 45%;
      left:25%;
      z-index: 100;
  }
}   */

/* @media screen and (min-width: 1171px) and (max-width: 1440px) {
  .slider {
      background-image: url(../../assets/images/slide-2.jpg);
  
      background-size: cover;
      background-attachment:scroll;
      background-position-y: top;
  }
  .slide-des{
      min-height: 50%;
      top:30%;
  }



}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
  .slider {
      background-image: url(../../assets/images/slide-2.jpg);

      background-size: cover;
      background-attachment:scroll;
      background-position-y: top;
  }
  .slide-des{
      min-height: 75%;
      top:40%;
  }
}
@media screen and (min-width: 1921px) {
  .slider {
   background-image: url(../../assets/images/slide-2.jpg);

      background-size: cover;
      background-attachment:scroll;
      background-position-y: top;
  }
  .slide-des{
      min-height:75%;
      top:40%;
  } 
} */









/* ============================================================================================================================================ */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
