/* .slideshow-container {

  position: relative;
  margin: auto;
}

.slides {
  display: none;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  margin-right: -45px;
  margin-left: -45px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 3px 0 0 3px;

}

.next {
  right: 0;
  border-radius: 0 3px 3px 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.navigator {
  text-align: center;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #481238;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #7c3f69;
} */


/* ///////////////////////////////////////// */
/* .slideshow-container {
    position: relative;
    max-width: 100%;
    margin: auto;
  }
  
  .slides {
    display: none;
  }
  
  .slides.active {
    display: block;
  }
  
  .slideshow-containe img {
    width: 100%;
    height: auto;
  }
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 3px 0 0 3px;
    margin-top: -22px;
    margin-right: -45px;
    margin-left: -45px;
  }
  
  .next {
    right: 0;
    border-radius: 0 3px 3px 0;
  }
  
  .navigator {
    text-align: center;
    padding: 10px 0;
  }
  
  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: #481238;
    border-radius: 50%;
    display: inline-block;
  }
  
  .dot.active {
    background-color: #7c3f69;
  } */
  /* ///////////////////////////////////////// */

  .slideshow-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .slide-wrapper {
    position: relative;
    height: 600px;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    cursor: pointer;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  button {
    position: absolute;
    /* top: 50%; */
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 16px 24px;
    font-size: 24px;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 50%;
    z-index: 10;
  }
  
  button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .prev {
    left: 20px;
  }
  
  .next {
    right: 20px;
  }
  
  .navigation-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
  }
  
  .dot {
    cursor: pointer;
    height: 12px;
    width: 12px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .dot.active {
    background-color: #fff;
    width: 24px;
    border-radius: 12px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .slideshow-container {
      border-radius: 8px;
    }
    
    .slide-wrapper {
      height: 400px;
    }
    
    button {
      padding: 12px 18px;
      font-size: 20px;
    }
    
    .navigation-dots {
      bottom: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .slide-wrapper {
      height: 250px;
    }
    
    button {
      padding: 10px 14px;
      font-size: 18px;
    }
    
    .prev { left: 10px; }
    .next { right: 10px; }
  }
 

/* Lightbox Modal Styles */
/* .lightbox-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
 
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  margin: auto;
  cursor: default;
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 30px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1001;
}

.modal-prev,
.modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 16px 24px;
  font-size: 36px;
  cursor: pointer;
  transition: background 0.3s;
  border-radius: 50%;
  z-index: 1001;
}

.modal-prev:hover,
.modal-next:hover {
  background: rgba(0, 0, 0, 0.8);
}

.modal-prev {
  left: 20px;
}

.modal-next {
  right: 20px;
} */

/* Responsive Modal Styles */
@media (max-width: 768px) {
  .modal-prev,
  .modal-next {
    padding: 12px 18px;
    font-size: 30px;
  }
  
  .close-modal {
    font-size: 36px;
    right: 15px;
  }
}

@media (max-width: 480px) {
  .modal-prev,
  .modal-next {
    padding: 10px 14px;
    font-size: 28px;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 100%;
  }
}

/* //////////////////////////////////// */
/* Add these styles to your existing CSS */

.lightbox-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  cursor: zoom-out;
}

.modal-image {
  position: fixed;
  transition: all 0.3s ease-out;
  object-fit: contain;
  max-width: 90%;
  max-height: 90%;
}

.modal-image.zoomed {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  width: auto !important;
  height: auto !important;
}

.close-modal {
  position: fixed;
  top: 20px;
  right: 30px;
  color: white;
  font-size: 40px;
  cursor: pointer;
}

.modal-prev, .modal-next {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 30px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 15px;
  cursor: pointer;
}

.modal-prev { left: 20px; }
.modal-next { right: 20px; }